import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useIsOnCallShift(facilityId?: string) {
  const { onCallAreas } = useCbhFlag(CbhFeatureFlag.PRICING_ON_CALL_SHIFTS, {
    defaultValue: {
      onCallAreas: [],
    },
  });

  const onCallWorkplaceIds = new Set(onCallAreas.map((area) => area.parentWorkplaceId));

  return isDefined(facilityId) && onCallWorkplaceIds.has(facilityId);
}
